import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { fetchHotCourses, fetchHomePageData } from './data/actions';

import './index.scss';

// Components
import Banner from '../components/Banner';
import CourseCard from '../components/CourseCard';
import CollegeBanner from '../components/CollegeBanner';

class HomePage extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    // this.props.fetchHotCourses();
    this.props.fetchHomePageData();
  }

  render() {
    const {
      hotCourses,
      homePageData,
    } = this.props;

    return (
      <>
        <Helmet>
          <title>{ homePageData.title }</title>
        </Helmet>
        <main>
          <Banner banners={ homePageData.banners } height={ 350 } />
          <div className="home-wrapper container-fluid">
            <CollegeBanner colleges={ homePageData.colleges } />
            {
              homePageData.body.map((item) => (
                <section key={ item.type }>
                  <h2>{ item.value.title }</h2>
                  <div className="courses-container">
                  {
                    item.value.contents && item.value.contents.map((course, index) => (
                      <CourseCard key={index} course={course} width={'25%'} />
                    ))
                  }
                  </div>
                </section>
              ))
            }
          </div>
        </main>
      </>
    );
  }
}

HomePage.propTypes = {
  hotCourses: PropTypes.array.isRequired,
  homePageData: PropTypes.object.isRequired,

  // Actions
  fetchHotCourses: PropTypes.func.isRequired,
  fetchHomePageData: PropTypes.func.isRequired,
}

HomePage.defaultProps = {
  hotCourses: [1, 2, 3, 4],
  homePageData: {
    banners: [],
    colleges: [],
    body: [],
  }
};

const mapStateToProps = state => {
  return {
    hotCourses: state.homePage.hotCourses,
    homePageData: state.homePage.homePageData,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchHotCourses: () => {
    dispatch(fetchHotCourses());
  },

  fetchHomePageData: () => {
    dispatch(fetchHomePageData());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePage);

import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import {
  fetchHotCoursesBegin,
  fetchHotCoursesReset,
  fetchHotCoursesSuccess,
  FETCH_HOT_COURSES,
  fetchHomePageDataBegin,
  fetchHomePageDataSuccess,
  fetchHomePageDataFailure,
  FETCH_HOME_PAGE_DATA,
} from './actions';

import * as HomePageApiService from './services';

export function* handleFetchHotCourses(action) {
  try {
    yield put(fetchHotCoursesBegin());

    let hotCourses = yield call(
      HomePageApiService.fetchHotCourses,
    );

    yield put(fetchHotCoursesSuccess({hotCourses: [1, 2, 3, 4, 5]}));
    // yield put(fetchHotCoursesReset());
  } catch (e) {
    if (e.response.status === 404) {
      history.push('/notfound');
    } else {
      throw e;
    }
  }
}

export function* handleFetchHomePageData(action) {
  try {
    yield put(fetchHomePageDataBegin());

    let homePageData = yield call(
      HomePageApiService.fetchHomePageData,
    );

    yield put(fetchHomePageDataSuccess(homePageData));
  } catch (e) {
    
  }
}

export default function* homePageSaga() {
  yield takeEvery(FETCH_HOT_COURSES.BASE, handleFetchHotCourses);
  yield takeEvery(FETCH_HOME_PAGE_DATA.BASE, handleFetchHomePageData);
}

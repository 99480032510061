import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import {
  fetchCoursesBegin,
  fetchCoursesSuccess,
  fetchCoursesFailure,
  FETCH_COURSES,
} from './actions';

import * as CoursesApiService from './services';

export function* handleFetchCourses(action) {
  const { search, limit, offset, path } = action.payload;

  try {
    yield put(fetchCoursesBegin());

    let courses = yield call(
      CoursesApiService.fetchCourses, search, limit, offset, path
    );

    yield put(fetchCoursesSuccess(courses));
  } catch (e) {
    
  }
}

export default function* coursesPageSaga() {
  yield takeEvery(FETCH_COURSES.BASE, handleFetchCourses);
}

import axios from 'axios';
import { getConfig } from '@edx/frontend-platform';
import { colleges, tabContents } from '../../mock';
const isMock = parseInt(process.env.MOCK) === 1;


export async function fetchColleges(id) {
  if (isMock) {
    return colleges;
  }

  const url = `${getConfig().DISCOVERY_BASE_URL}/portal/api/v1/pages/${id}/`;
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchTabContents(id) {
  if (isMock) {
    return tabContents;
  }

  const url = `${getConfig().DISCOVERY_BASE_URL}/portal/api/v1/pages/${id}/`;
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

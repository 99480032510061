import {
  FETCH_COURSES,
} from './actions';

export const initialState = {
  courses: {
    count: 0,
    results: [],
  }
};

const coursesPage = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSES.BEGIN:
      return {
        ...state,
      };
    case FETCH_COURSES.SUCCESS:
      return {
        ...state,
        courses: action.payload.courses,
      };
    default:
      return state;
  }
};

export default coursesPage;

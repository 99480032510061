import 'babel-polyfill';

import {
  APP_INIT_ERROR, APP_READY, APP_CONFIG_INITIALIZED, subscribe, initialize, mergeConfig, getConfig
} from '@edx/frontend-platform';
import { fetchAuthenticatedUser, hydrateAuthenticatedUser, getAuthenticatedUser } from '@edx/frontend-platform/auth';
import { AppProvider, ErrorPage } from '@edx/frontend-platform/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';


import Header, { messages as headerMessages } from 'frontend-component-header-firacademy';
import Footer from './components/Footer';

import appMessages from './i18n';
import HomePage from './HomePage/HomePage';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import AboutUs from './AboutUs/AboutUs';
import CollegePage from './CollegePage/CollegePage';
import CoursesPage from './CoursesPage/CoursesPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import SearchBar from './components/SearchBar';
import configureStore from './data/configureStore';


import './index.scss';
import './assets/favicon.ico';
import logo from './assets/logo.png';


subscribe(APP_CONFIG_INITIALIZED, () => {
  mergeConfig({
    DISCOVERY_BASE_URL: process.env.DISCOVERY_BASE_URL,
  });
});

subscribe(APP_READY, () => {
  // 课程，方案，直播最终会是一个搜索页，现在暂时都用courses页渲染
  ReactDOM.render(
    <AppProvider store={configureStore()}>
      <Header
        renderSearch={() => (
          <SearchBar searchPath={ ['/courses', '/programs', '/lives'] } />
        )}
        mainMenuType={'navlink'}
        onMainMenuAssigned={(mainMenu) => {
          for (let i = mainMenu.length - 1; i > 0; i--) {
            if (mainMenu[i].href === `${getConfig().MARKETING_SITE_BASE_URL}/lives`) {
              mainMenu.splice(i, 1);
              break;
            }
          }
        }}
        logo={logo}
        />
      <Switch>
        <Route exact path="/" component={ HomePage } />
        <Route path="/college/:id" component={ CollegePage } />
        <Route exact strict path="/courses" render={(props) => <CoursesPage title="课程" {...props} />} />
        <Route exact strict path="/programs" render={(props) => <CoursesPage title="方案" {...props} />} />
        <Route exact strict path="/lives" render={(props) => <CoursesPage title="直播" {...props} />} />
        <Route path="/about-us/" component={ AboutUs } />
        <Route path="/privacy-policy/" component={ PrivacyPolicy } />
        <Route path="*" component={ NotFoundPage } />
      </Switch>
      <Footer />
    </AppProvider>,
    document.getElementById('root'),
  );
});

subscribe(APP_INIT_ERROR, (error) => {
  ReactDOM.render(<ErrorPage message={error.message} />, document.getElementById('root'));
});

initialize({
  messages: [
    appMessages,
    headerMessages,
  ],
  handlers: {
    auth: async () => {
      try {
        await fetchAuthenticatedUser();

        if (getAuthenticatedUser() !== null) {
          hydrateAuthenticatedUser();
        }
      } catch(e) {
        // 先初始化用户信息，获取jwt token可能失败，失败之后不强制要求登录
      }
    },
  },
});

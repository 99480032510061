import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  'portal.courses.head.title_courses': {
    id: 'portal.courses.head.title_courses',
    defaultMessage: 'Courses'
  },
  'portal.courses.head.title_programs': {
    id: 'portal.courses.head.title_programs',
    defaultMessage: 'Programs'
  },
  'portal.courses.head.title_lives': {
    id: 'portal.courses.head.title_lives',
    defaultMessage: 'Lives'
  },
  'portal.courses.head.pageTitle': {
    id: 'portal.courses.head.pageTitle',
    defaultMessage: '{pageType} | {siteName}'
  },
  'portal.courses.nav.allContent': {
    id: 'portal.courses.nav.allContent',
    defaultMessage: 'All {pageType}'
  }
});

export default messages;
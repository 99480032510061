import {
  FETCH_COLLEGES,
  FETCH_TAB_CONTENTS,
} from './actions';

export const initialState = {
  colleges: {
    banners: [],
    menus: [],
    body: [],
  },
  tabContents: [],
  indexContents: [],
};

const collegePage = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COLLEGES.BEGIN:
      return {
        ...state,
      };
    case FETCH_COLLEGES.SUCCESS:
      return {
        ...state,
        colleges: action.payload.colleges,
        indexContents: action.payload.colleges.body,
        tabContents: action.payload.colleges.body,
      };
    case FETCH_TAB_CONTENTS.BEGIN:
      return {
        ...state,
      };
    case FETCH_TAB_CONTENTS.SUCCESS:
      return {
        ...state,
        tabContents: action.payload.body,
      };
    default:
      return state;
  }
};

export default collegePage;

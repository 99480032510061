import { AsyncActionType } from '../../utils';

export const FETCH_COURSES = new AsyncActionType('COURSESPAGE', 'FETCH_COURSES');


export const fetchCourses = (search, limit, offset, path) => ({
  type: FETCH_COURSES.BASE,
  payload: { search, limit, offset, path }
});

export const fetchCoursesBegin = () => ({
  type: FETCH_COURSES.BEGIN,
});

export const fetchCoursesSuccess = (courses) => ({
  type: FETCH_COURSES.SUCCESS,
  payload: { courses },
});

export const fetchCoursesFailure = () => ({
  type: FETCH_COURSES.FAILURE,
});

export const fetchCoursesReset = () => ({
  type: FETCH_COURSES.RESET,
});

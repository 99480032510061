import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import './index.scss';

class AboutUs extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>关于我们</title>
        </Helmet>
        <main>
          <section>
            <div>
              <img style={{ height: '372px' }} src="http://fira-dev-public.oss-cn-zhangjiakou.aliyuncs.com/media%2Foriginal_images%2Fimages_Song_Hu_Xue_Yuan_.original.original.jpg" alt="红杉学院" />
            </div>
          </section>
          <section>
            <div className="about-wrapper container-fluid">
              <h3 className="about-title">
                关于我们
              </h3>
              <p>红杉学院服务于处在第四次工业革命（the Fouth Industry Revolution, FIR)前沿的中国企业。这些企业包括ICT、金融、能源、教育、交通、制造、保险、医疗等。红杉学院充分利用云计算、大数据、人工智能、富媒体、慕课等先进技术，为中国在数字化转型途中的企业提供世界一流的组织能力锻造平台，即FIRA平台。在平台基础上，红杉学院为企业提供优异的、面向组织能力建设的咨询服务。服务包括结构化学习方法论、企业能力建设框架、企业训战课程框架、案例研讨框架、能力考核框架、短板分析等。通过红杉学院组织能力建设平台、内容框架和方法论，企业可以有效的培养和建设第四次工业革命浪潮中所需要的学习型组织，在企业数字化转型中立于不败之地。</p>
              <h3 className="about-title">
                公司愿景
              </h3>
              <p>通过数字化学习手段，使能企业数字化转型中的关键组织能力建设和积累。</p>
            </div>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutUs);

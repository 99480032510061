import { all } from 'redux-saga/effects';

import { saga as homeSaga } from '../HomePage';
import { saga as collegeSaga } from '../CollegePage';
import { saga as coursesPageSaga } from '../CoursesPage';

export default function* rootSaga() {
  yield all([
    homeSaga(),
    collegeSaga(),
    coursesPageSaga(),
  ]);
}

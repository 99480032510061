import {
  FETCH_HOT_COURSES,
  FETCH_HOME_PAGE_DATA,
} from './actions';

export const initialState = {
  hotCourses: [],
  homePageData: {
    banners: [],
    colleges: [],
    body: [],
  },
};

const homePage = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOT_COURSES.BEGIN:
      return {
        ...state,
      };
    case FETCH_HOT_COURSES.SUCCESS:
      return {
        ...state,
        hotCourses: action.payload.hotCourses,
      };
    case FETCH_HOME_PAGE_DATA.BEGIN:
      return {
        ...state,
      };
    case FETCH_HOME_PAGE_DATA.SUCCESS:
      return {
        ...state,
        homePageData: action.payload.homePageData,
      };
    default:
      return state;
  }
};

export default homePage;

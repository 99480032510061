import React from 'react';

import './Footer.scss';
import edx from './assets/logo-footer-edx.png';
import openedx from './assets/logo-footer-openedx.png';

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer>
        <div className="footer-wrapper">
          <div className="foot-logo">
            <a target="_blank" href="https://www.edx.org/"><img className="edx" src={edx} alt="edxLogo" /></a>
          </div>
          <div className="secondary">
            <p>© 2020 edX Inc. All rights reserved. | 东莞红杉教育科技有限公司 <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备20015163号-2</a></p>
            <p>
              <a target="_blank" href="https://www.edx.org/about-us">关于我们</a>
              <a target="_blank" href="https://www.edx.org/edx-privacy-policy">隐私协议</a>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';

import './Banner.scss';

// Components
import Carousel from 'antd/es/carousel';
import 'antd/es/carousel/style/css';

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidUpdate() {

  }

  render() {
    const {
      banners,
      height
    } = this.props;

    return (
      <section className='overflow-hidden' style={{height: height}}>
        <Carousel autoplay>
          {
            banners.map((banner) => (
              <div key={ banner.id } className="banner-item">
                <span className="banner-text">{ banner.value.text }</span>
                <a target="_blank" href={ banner.value.url } className="banner-link" style={{ backgroundImage: "url('" + banner.value.image + "')", height: this.props.height }}></a>
              </div>
            ))
          }
        </Carousel>
      </section>
    );
  }
}

Banner.propTypes = {
  banners: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
};

Banner.defaultProps = {
  banners: [],
  height: 350,
};

export default Banner;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import './SearchBar.scss';
import 'antd/es/input/style/css';

import Input from 'antd/es/input';
const { Search } = Input;

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
  }

  search = value => {
    if (value.trim() !== '') {
      let i = this.props.searchPath.indexOf(this.props.location.pathname);
      i = i > -1 ? i : 0;
      this.props.history.push(`${this.props.searchPath[i]}?q=${encodeURIComponent(value)}`);
    }
  }

  render() {
    return (
      <Search
        placeholder="搜索"
        onSearch={this.search}
        style={{width: 220, marginLeft: 35, height: 38}}
        />
    );
  }
}

SearchBar.propTypes = {
};

SearchBar.defaultProps = {
};

export default withRouter(SearchBar);

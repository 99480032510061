import axios from 'axios';
import { ensureConfig, getConfig } from '@edx/frontend-platform';
import { courses } from '../../mock';
const isMock = parseInt(process.env.MOCK) === 1;


export async function fetchCourses(q, page_size, page, path) {
  if (isMock) {
    return courses;
  }
  const url = `${getConfig().DISCOVERY_BASE_URL}/portal/api/v1/search/${path.replace(/(^\/|\/$)/g, '')}/?host=${window.location.hostname}`;
  try {
    const { data } = await axios.get(url, {withCredentials: true, params: { q, page_size, page }});
    return data;
  } catch (e) {
    throw e;
  }
}

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { fetchColleges, fetchTabContents } from './data/actions';

import './index.scss';

// Components
import Menu from 'antd/es/menu';
import 'antd/es/menu/style/css';
import Banner from '../components/Banner';
import CourseCard from '../components/CourseCard';


class CollegePage extends React.Component {
  static IndexMenu = [
    {
      id: 'ab5efa8e-72a6-4e65-be8d-bb6569db4e52',
      type: 'menu',
      value: {
        pid: -1,
        url: '',
        title: '首页'
      }
    }
  ]

  constructor(props, context) {
    super(props, context);
    this.state = {
      currentMenu: '首页',
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchColleges(id);
  }

  handleMenuClick = e => {
    this.setState({
      currentMenu: e.key,
    });
    
    if (e.key === '首页') {
      this.props.fetchTabContents(null, this.props.indexContents);
    } else {
      this.props.fetchTabContents(e.item.props.pid, null);
    }
  }

  render() {
    const {
      colleges,
      tabContents,
    } = this.props;

    return (
      <>
        <Helmet>
          <title>{ colleges.title }</title>
        </Helmet>
        <main>
          <Banner banners={ colleges.banners } height={ 350 } />
          <div className="college-wrapper container-fluid">
            <div className="menu-wrapper">
              <Menu onClick={ this.handleMenuClick } selectedKeys={ [this.state.currentMenu] } mode="horizontal">
                {
                  CollegePage.IndexMenu.concat(colleges.menus).map((menu) => (
                    <Menu.Item key={ menu.value.title } pid={ menu.value.pid }>
                      { menu.value.title }
                    </Menu.Item>
                  ))
                }
              </Menu>
            </div>
            {
              tabContents.map((item) => (
                <section key={ item.type }>
                  {
                    item.type === 'html' ? (
                      <div dangerouslySetInnerHTML = {{ __html: item.value  }}  style={{ overflow: 'scroll' }} />
                    ) : (
                      <>
                        <h2>{ item.value.title }</h2>
                        <div className="courses-container">
                          {
                            item.value.contents && item.value.contents.map((course, index) => (
                              <CourseCard key={index} course={course} width={'25%'} />
                            ))
                          }
                        </div>
                      </>
                    )
                  }
                </section>
              ))
            }
          </div>
        </main>
      </>
    );
  }
}

CollegePage.propTypes = {
  colleges: PropTypes.object.isRequired,
  tabContents: PropTypes.array.isRequired,
  indexContents: PropTypes.array.isRequired,

  // Actions
  fetchColleges: PropTypes.func.isRequired,
}

CollegePage.defaultProps = {
  colleges: {
    banners: [],
    menus: [],
    body: [],
  },
  tabContents: [],
  indexContents: [],
};

const mapStateToProps = state => {
  return {
    colleges: state.collegePage.colleges,
    tabContents: state.collegePage.tabContents,
    indexContents: state.collegePage.indexContents,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchColleges: (id) => {
    dispatch(fetchColleges(id));
  },
  fetchTabContents: (id, contents) => {
    dispatch(fetchTabContents(id, contents));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollegePage);

import axios from 'axios';
import queryString from 'query-string';
import { ensureConfig, getConfig } from '@edx/frontend-platform';
import { homePageData } from '../../mock';
const isMock = parseInt(process.env.MOCK) === 1;

export async function fetchHotCourses() {
  const url = `http://courses.myopenedx.com:9000/`;
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    return [];
  }
}

export async function fetchHomePageData() {
  if (isMock) {
    return homePageData;
  }

  const query = queryString.parse(window.location.search);
  let token = query.token;
  let content_type = query.content_type;

  let url = `${getConfig().DISCOVERY_BASE_URL}/portal/api/v1/pages/index/?host=${window.location.hostname}`;
  if (token && content_type) {
      url = `${getConfig().DISCOVERY_BASE_URL}/portal/api/v1/preview/1/?host=${window.location.hostname}&token=${token}&content_type=${content_type}`;
  }

  const login_url = `${getConfig().LOGIN_URL}?next=${encodeURIComponent(window.location.href)}`;
  try {
    const { data } = await axios.get(url, {withCredentials: true});
    return data;
  } catch (e) {
    if (e.response.status == 401) {
      window.location.replace(login_url);
    } else if (e.response.status == 403) {
      alert("您无权限访问该页面，请联系企业管理员添加权限后重新登录。");
    } else if (e.response.status == 404) {
      window.location.replace('/notfound');
    } else {
      throw e;
    }
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import './CollegeBanner.scss';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';

class CollegeBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      pageSize: 5,
    };
  }

  handleCollegePageReduce = e => {
    let page = this.state.page - 1;
    if (page < 0) return;
    this.setState({
      page,
    });
  }

  handleCollegePageIncrease = e => {
    let page = this.state.page + 1;
    if (page > Math.ceil(this.props.colleges.length / this.state.pageSize) - 1) return;
    this.setState({
      page,
    });
  }

  render() {
    const {
      colleges,
    } = this.props;
    let PAGE = this.state.page;
    let PAGE_SIZE = this.state.pageSize;
    let TOTAL_PAGE = Math.ceil((colleges.length / PAGE_SIZE));

    // 40为item之间的间隙,在css中定义,暂时不挪过来
    let itemWidth = `calc((100% - ${PAGE_SIZE - 1} * 40px) / ${PAGE_SIZE})`;

    // 140为左右两个箭头的宽和margin之和,在css中定义,暂时不挪过来
    let ulWidth = `calc(100% - 140px)`;

    return (
      <div className="subportal-wrapper">
        {
          TOTAL_PAGE > 1
            &&
          <i
            className={`subportal-arrow arrow-left ${PAGE <= 0 ? 'disabled' : 'enabled'}`}
            onClick={ this.handleCollegePageReduce }>
            <CaretLeftOutlined />
          </i>
        }
        <ul style={{ width: ulWidth}}>
          {
            colleges.slice(PAGE * PAGE_SIZE, (PAGE + 1) * PAGE_SIZE ).map((college) => (
              <li key={ college.id } style={{ width: itemWidth }}>
                <a target="_blank" href={ '/college/' + college.value.pid } className="subportal-name">
                  {
                    college.value.image ? <img src={ college.value.image } /> : <span>{ college.value.title }</span>
                  }
                </a>
              </li>
            ))
          }
        </ul>
        {
          TOTAL_PAGE > 1
            &&
          <i
            className={`subportal-arrow arrow-right ${PAGE >= TOTAL_PAGE - 1 ? 'disabled' : 'enabled'}`}
            onClick={ this.handleCollegePageIncrease }>
            <CaretRightOutlined />
          </i>
        }
      </div>
    );
  }
}

CollegeBanner.propTypes = {
  colleges: PropTypes.array.isRequired,
};

CollegeBanner.defaultProps = {
  colleges: [],
};

export default CollegeBanner;

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import './index.scss';

class PrivacyPolicy extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>红杉学院隐私协议</title>
        </Helmet>
        <main>
          <section>
            <div>
              <img style={{ height: '372px' }} src="http://fira-dev-public.oss-cn-zhangjiakou.aliyuncs.com/media%2Foriginal_images%2Fimages_Song_Hu_Xue_Yuan_.original.original.jpg" alt="红杉学院" />
            </div>
          </section>
          <section>
            <div className="privacy-wrapper container-fluid">
              <h3 className="privacy-title">
                隐私政策声明
              </h3>
              <p>本公司（以下亦称“我们”、“红杉学院FIRA平台”）深知隐私对您的重要性，并会尊重您的隐私。请在向FIRA平台提交个人数据之前，阅读、了解本《隐私政策声明》（以下简称“本政策”）。本政策适用于显示本隐私政策、或链接至本隐私政策的FIRA平台网站和产品、服务。</p>
              <p>本政策阐述了FIRA平台如何处理您的个人数据，但本政策可能并不涉及所有可能的处理情境。有关FIRA平台服务收集的特定数据信息由FIRA平台在补充政策中，或者在其收集数据时提供的通知中发布。</p>
              <p>本政策属于《红杉学院FIRA平台用户协议》不可分割的一部分。您同意《红杉学院FIRA平台协议》，即视为您已经阅读并同意本政策的全部内容，如果您不同意本政策的条款，应停止使用服务。</p>
              <p>我们制定本政策的目的在于帮助您了解以下内容：</p>
              <p>一、FIRA平台如何收集和使用您的个人数据</p>
              <p>二、FIRA平台如何披露您的个人数据</p>
              <p>三、使用 Cookie</p>
              <p>四、FIRA平台如何保护您的个人数据</p>
              <p>五、如何访问或修改您的个人数据</p>
              <p>六、FIRA平台如何处理儿童的个人数据</p>
              <p>七、第三方提供商及其服务</p>
              <p>八、您的个人数据的保存和跨境转移</p>
              <p>九、本政策如何更新</p>
              <p>十、联系红杉学院FIRA平台</p>
              <h2 className="privacy-chapter">一、红杉学院FIRA平台如何收集和使用您的个人数据</h2>
              <h3>1.1 个人数据的范围</h3>
              <p>个人数据是指单独使用或结合其他信息使用时能够确定个人身份的信息，包括但不限于：</p>
              <p>a) 在您注册FIRA平台账户时，您提供的个人数据；</p>
              <p>b) FIRA平台因为您访问和使用【<a href="http://www.firacademy.com" target="_blank">http://www.firacademy.com</a>】网页或服务而接收并记录的您的相关信息，包括但不限于您访问的网页记录、访问日期和时间、使用的浏览器的信息（类型、时区、插件、语言、字体、canvas图片渲染哈希值）、您在页面上的鼠标和键盘操作信息、软硬件特征、网络链接信息（IP地址、端口、网络协议等）、您的课程学习记录、考试记录等数据；</p>
              <p>c) 您与我们互动或因使用服务或获得支持和帮助时提供的个人数据；</p>
              <p>d) FIRA平台通过合法途径从商业伙伴处或第三方取得的数据。例如在法律允许的情况下，我们会从公用和商用第三方来源获取有关数据来支持我们的服务。</p>
              <p>通常情况下，您不需要提供个人数据就可以浏览FIRA平台网站的对外公开信息。为了给您提供服务，FIRA平台会按照本隐私政策的规定保护、使用和披露您的个人数据。为服务之目的，如果您以自愿填写的方式或根据要求提供注册或订阅所需的姓名、性别、证件种类及证件号、出生年月日、国籍、电子邮件信箱、电话、联系地址和邮政编码、所希望提供的服务或喜好信息以及其他类似的个人数据，则表示您已经了解并接受FIRA平台依照本隐私政策对您的个人数据的收集、保护和使用。</p>
              <h3>1.2 个人数据的使用</h3>
              <p>我们可能将您的个人数据用于以下目的：</p>
              <p>(a) 履行订单；应您的要求进行变更；以及提供技术支持和服务；</p>
              <p>(b) 向您发送重要通知；</p>
              <p>(c) 开展内部审计、数据分析和研究，改善我们的产品和服务；</p>
              <p>(d) 分析业务运营效率并衡量市场份额；</p>
              <p>(e) 改善我们的防损和反欺诈计划；</p>
              <p>(f) 遵从和执行适用的法律法规要求，相关的行业标准或我们的政策；</p>
              <p>(g) 与您进行联系，向您发送有关您可能感兴趣的产品和服务的信息，邀请您参加FIRA平台活动和市场调查；或向您发送营销信息。如果您不想接收此类信息，您可以选择退订；</p>
              <p>(h) 其它为向您提供服务而需要使用您个人数据的情形。</p>
              <h2 className="privacy-chapter">二、红杉学院FIRA平台如何披露您的个人数据</h2>
              <h3>（一）委托处理</h3>
              <p>一些情况下，我们会委托其他公司代表红杉学院处理您的个人信息。例如代表我们处理热线、发送电子邮件、提供技术支持的公司，这些公司只能将您的个人信息用于代表红杉学院向您提供服务。 我们会与被委托方签署严格的保密协议或个人信息处理条款，要求他们按照本声明以及采取相关的保密和安全措施来处理个人信息。</p>
              <h3>（二）共享</h3>
              <p>共享是指我们向其他个人信息控制者提供个人信息，且双方分别对个人信息拥有独立控制权的过程。红杉学院不会对外共享您的个人信息，但以下情况除外：</p>
              <p>1. 在获取明确同意情况下的共享：获得您的明确同意后，我们会向您指定的第三方共享您授权范围内的信息；</p>
              <p>2. 在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；</p>
              <p>3. 共享给我们的附属公司：您的信息可能会在我们的附属公司内共享。我们仅会出于特定、明确而合法的目的在我们的附属公司内共享您的信息，并且只会共享提供服务所必要的信息。例如，在注册FIRA帐号时为了避免重复注册，我们需要对拟注册的账号进行全球唯一性校验。</p>
              <p>4. 共享给业务合作伙伴：我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴包括：
                <p>1) 第三方卖家和第三方开发者：某些产品或服务由第三方直接向您提供，我们须将交易相关信息共享给第三方来实现您向其购买商品或服务的需求。例如，您在应用市场内购买其他开发者的商品时，我们须与开发者共享必要的您的信息，交易才能完成。</p>
                <p>2) 商品或技术服务的供应商。我们为可能会将您的个人信息共享给支持我们功能的第三方，包括为我们供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理服务的第三方等。我们共享这些信息的目的是为实现产品及服务的功能，比如我们必须与物流服务提供商共享您的订单信息以安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。</p>
                <p>在3-4下，我们会与共享个人信息的公司、组织和个人其签署严格的保密协议，要求他们按照本声明以及采取相关的保密和安全措施来处理个人信息。</p>
              </p>
              <h3>（三）转让</h3>
              <p>转让，是指将个人信息控制权由红杉学院向另一个个人信息控制者转移的过程。我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
              <p>在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
              <p>在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受本声明的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
              <h3>（四）公开披露</h3>
              <p>红杉学院仅会在以下情况下，公开披露您的个人信息：</p>
              <p>获得您的明确同意后；</p>
              <p>基于法律或合理依据的公开披露：在法律、法律程序、诉讼或公共和政府主管部门有要求的情况下，我们可能会公开披露您的信息。</p>
              <h2 className="privacy-chapter">三、使用Cookie</h2>
              <p>a) 为了能够向您提供依赖于cookie的功能和服务，或为保证网站正常运转，FIRA平台会根据业务需要在您的计算机上或移动设备上存储、设定或取用cookies。Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储用户偏好或采购篮中的商品及服务等数据；</p>
              <p>b) FIRA平台启用Cookie的目的与大多数网站或互联网服务提供商启用 Cookie 的目的一样，即改善用户体验。借助于 Cookie，网站能够记住用户的单次访问（使用会话 Cookie）或多次访问（使用永久 Cookie）。借助于 Cookie，网站能够保存设置，例如计算机或移动设备的语言、字体大小和其他浏览偏好。这意味着，用户无需在每次访问时重新配置用户偏好设置。如果某个网站不使用 Cookie，那么在用户每一次打开网页时，该网站都会将其视为新访客。例如，如果您登录某个网站后转到另一个网页，该网站就不会识别出您，而您会被再次注销。FIRA平台不会将 Cookie 用于本政策所述目的之外的其他用途；</p>
              <p>c) 您可通过自行修改浏览器设置的方式拒绝或管理cookie。若您未修改浏览器设置以拒绝接受cookie，视为您同意接受cookie。您理解并同意，您拒绝接受cookie可能导致您无法登录或使用需依赖于cookie的FIRA平台官网的服务或功能，由此可能产生的后果将由您自行承担，FIRA平台不承担任何责任；</p>
              <p>d) 通过FIRA平台所设cookie所取得的有关信息，将适用本政策的约定。</p>
              <h2 className="privacy-chapter">四、红杉学院FIRA平台如何保护您的个人数据</h2>
              <p>a) FIRA平台重视个人数据的安全。我们采用适当的物理、管理和技术保障措施来保护您的个人数据不被未经授权访问、披露、使用、修改、损坏或丢失。例如，我们会使用加密技术确保数据的机密性；我们会使用保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人数据；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人数据重要性的认识。我们将会在达成本政策所述目的所需的期限内保留您的个人数据，除非需要延长保留期或受到法律的允许。我们会尽力保护您的个人数据，但是请注意任何安全措施都无法做到无懈可击；</p>
              <p>b) 尽管有前述安全措施，但妥善保管您的账户及密码及其他信息的安全是您的责任和义务，因您未能保管好账户和密码而导致的法律责任由您全部承担；</p>
              <p>c) 如果发现您账号或密码被他人非法使用或有使用异常的情况的，应及时通知FIRA平台，您可要求FIRA平台采取措施暂停您账户的登录和使用。</p>
              <p>d)如不幸发生个人信息泄露等安全事件，FIRA平台将依照适用的法律法规的要求，以网站公告或邮件的方式，向您和或主管机关告知数据泄露事件的相关信息。如果因完全归因于FIRA平台的原因造成您的个人信息泄露，在FIRA平台确认相关数据泄露事件及根因后，FIRA平台将尽合理努力不迟延地向您发送数据泄露的通知。</p>
              <h2 className="privacy-chapter">五、如何访问或修改您的个人数据</h2>
              <p>您应确保提交的所有个人数据都准确无误。FIRA平台会尽力维护个人数据的准确和完整，并及时更新这些数据。</p>
              <p>为遵守适用法律的明确要求，您可能(1)有权访问我们持有的关于您的特定的个人数据；(2)要求我们更新或更正您的不准确的个人数据；以及(3)依照法律规定要求我们删除您的个人数据。如果您想行使相关的权利，请发送邮件通知我们。为保障安全，您可能需要提供书面请求。如果我们有合理依据认为这些请求存在欺骗性、无法实行或损害他人隐私权或权益，我们则会拒绝处理请求。</p>
              <h2 className="privacy-chapter">六、红杉学院FIRA平台如何处理儿童的个人数据</h2>
              <p>我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户帐户。对于经父母同意而收集儿童个人数据的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或披露此数据。</p>
              <p>如果FIRA平台发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人数据，则会设法尽快删除相关数据。</p>
              <h2 className="privacy-chapter">七、 第三方提供商及其服务</h2>
              <p>红杉学院无法控制第三方的隐私和数据保护政策，此类第三方内容不受到本政策的约束。在向第三方或通过第三方内容提交个人信息之前，请参见这些第三方的隐私保护政策。</p>
              <h2 className="privacy-chapter">八、 您的个人数据的保存和跨境转移</h2>
              <p>FIRA平台收集的您的信息和资料将保存在FIRA平台及其关联公司、服务提供商/分包商所在国家/地区。FIRA平台目前在中国大陆直接运营并提供服务。目前在中国大陆建立有数据中心。这些信息和资料也可能传送至您所在国家/地区或FIRA平台收集信息和资料所在地的境外，并在境外被访问、存储和展示。在此类情况下，FIRA平台会采取措施确保我们收集的数据依据本政策和数据所在地适用法律的要求进行处理。</p>
              <h2 className="privacy-chapter">九、 本政策如何更新</h2>
              <p>FIRA平台保留不时更新或修改本政策的权利。如果我们的隐私政策变更，我们会将最新版隐私政策发布在FIRA平台网站上，并自发布时立即生效。您应当主动查阅本政策的内容。如果我们对隐私政策做出了重大变更，我们还可能通过您的联系方式单独向您发送变更通知，您应注意查收。</p>
              <h2 className="privacy-chapter">十、联系我们</h2>
              <p>如果您有任何关于本申明或隐私保护的疑问，请通过<a href="mailto:fira@firacademy.com">fira@firacademy.com</a>联系FIRA平台。</p>
              <p>最近更新日期：2020年3月</p>
            </div>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivacyPolicy);

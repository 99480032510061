import { combineReducers } from 'redux';

import { reducer as homePage } from '../HomePage';
import { reducer as collegePage } from '../CollegePage';
import { reducer as coursesPage } from '../CoursesPage';

const createRootReducer = () =>
  combineReducers({
    homePage,
    collegePage,
    coursesPage,
  });

export default createRootReducer;

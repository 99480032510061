import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { fetchCourses } from './data/actions';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import messages from './messages';
import './index.scss';

// Components
import Pagination from 'antd/es/pagination';
import 'antd/es/pagination/style/css';
import CourseCard from '../components/CourseCard';

class CoursesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      current: 1,
      pageSize: 16,
    };
  }

  fetchCourses() {
    const query = queryString.parse(this.props.location.search);
    let search = query.q;
    let { current, pageSize } = this.state;
    this.props.fetchCourses(search, pageSize, current, this.props.match.path);
  }

  onChange = page => {
    this.setState({
      current: page,
    }, () => {
      this.fetchCourses();
    });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search || location.pathname !== prevProps.location.pathname) {
      this.setState({
        current: 1,
      }, () => {
        this.fetchCourses();
      });
    }
  }

  componentDidMount() {
    this.fetchCourses();
  }

  render() {
    const {
      intl,
      courses,
    } = this.props;
    const query = queryString.parse(this.props.location.search);
    const pageTitleText = intl.formatMessage(messages[`portal.courses.head.title_${this.props.title}`]);

    return (
      <>
      <Helmet>
        <title>{intl.formatMessage(messages['portal.courses.head.pageTitle'], { siteName: process.env.SITE_NAME, pageType: pageTitleText })}</title>
      </Helmet>
        <main>
          <div className="course-wrapper container-fluid">
            <div className="course-top">
              <Link to={this.props.location.pathname}>{intl.formatMessage(messages['portal.courses.nav.allContent'], { pageType: pageTitleText })}</Link>
              {
                !!query.q 
                &&
                <span>&nbsp;&gt;&nbsp;"{ query.q }"</span>
              }
            </div>
            <div className="courses-container">
              {
                courses.results.map((course) => (
                  <CourseCard key={course.id} course={course} width={'25%'} />
                ))
              }
            </div>
            {
              courses.count > 0
                &&
              <div className="course-pagination">
                <Pagination
                  total={courses.count}
                  pageSize={this.state.pageSize}
                  current={this.state.current}
                  showSizeChanger={false}
                  showQuickJumper
                  showTotal={total => `共 ${total} 个课程`}
                  onChange={this.onChange}
                />
              </div>
            }
          </div>
        </main>
      </>
    );
  }
}

CoursesPage.propTypes = {
  intl: intlShape.isRequired,
  courses: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,

  // Actions
  fetchCourses: PropTypes.func.isRequired,
}

CoursesPage.defaultProps = {
  title: '',
  courses: {
    count: 0,
    results: [],
  }
};

const mapStateToProps = state => {
  return {
    courses: state.coursesPage.courses,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCourses: (search, page_size, page, path) => {
    dispatch(fetchCourses(search, page_size, page, path));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CoursesPage));

import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import {
  fetchCollegesBegin,
  fetchCollegesSuccess,
  fetchCollegesFailure,
  FETCH_COLLEGES,
  fetchTabContentsBegin,
  fetchTabContentsSuccess,
  fetchTabContentsFailure,
  FETCH_TAB_CONTENTS,
} from './actions';

import * as CollegePageApiService from './services';


export function* handleFetchColleges(action) {
  const { id } = action.payload;
  try {
    yield put(fetchCollegesBegin());

    let colleges = yield call(
      CollegePageApiService.fetchColleges, id
    );

    yield put(fetchCollegesSuccess(colleges));
  } catch (e) {
    
  }
}

export function* handlefetchTabContents(action) {
  const { id, contents } = action.payload;

  try {
    yield put(fetchTabContentsBegin());

    let tabs = contents !== null ? { body: contents } : yield call(
      CollegePageApiService.fetchTabContents, id
    );

    yield put(fetchTabContentsSuccess(tabs));
  } catch (e) {
    
  }
}

export default function* collegePageSaga() {
  yield takeEvery(FETCH_COLLEGES.BASE, handleFetchColleges);
  yield takeEvery(FETCH_TAB_CONTENTS.BASE, handlefetchTabContents);
}

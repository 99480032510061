import React from 'react';
import { connect } from 'react-redux';

import './index.scss';
import NOT_FOUND from '../assets/404.jpeg';

class NotFoundPage extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <main>
        <section>
          <div className="page-error">
            <img src={ NOT_FOUND } />
            <h1 className="error-text">
              您访问的页面不存在!
            </h1>
            <p>
              未能找到您查找的页面。返回到
              <a href="/">主页</a>
              或发电子邮件至
              <a href="mailto:fira@firacademy.com">fira@firacademy.com</a>
              告诉我们可能被移除的页面
            </p>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotFoundPage);

import { AsyncActionType } from '../../utils';

export const FETCH_HOT_COURSES = new AsyncActionType('COURSES', 'FETCH_HOT_COURSES');
export const FETCH_HOME_PAGE_DATA = new AsyncActionType('HOMEPAGE', 'FETCH_HOME_PAGE_DATA');


// FETCH HOT COURSES ACTIONS
export const fetchHotCourses = () => ({
  type: FETCH_HOT_COURSES.BASE,
});

export const fetchHotCoursesBegin = () => ({
  type: FETCH_HOT_COURSES.BEGIN,
});

export const fetchHotCoursesSuccess = (payload) => ({
  type: FETCH_HOT_COURSES.SUCCESS,
  payload,
});

export const fetchHotCoursesReset = () => ({
  type: FETCH_HOT_COURSES.RESET,
});

// FETCH HomePageData ACTIONS
export const fetchHomePageData = () => ({
  type: FETCH_HOME_PAGE_DATA.BASE,
});

export const fetchHomePageDataBegin = () => ({
  type: FETCH_HOME_PAGE_DATA.BEGIN,
});

export const fetchHomePageDataSuccess = (homePageData) => ({
  type: FETCH_HOME_PAGE_DATA.SUCCESS,
  payload: { homePageData },
});

export const fetchHomePageDataFailure = () => ({
  type: FETCH_HOME_PAGE_DATA.FAILURE,
});

export const fetchHomePageDataReset = () => ({
  type: FETCH_HOME_PAGE_DATA.RESET,
});

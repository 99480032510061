import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import './CourseCard.scss';
import DefaultImg from '../../assets/default.png';

class CourseCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      course,
    } = this.props;
    const ph = React.createElement('img', {src: DefaultImg});
    return (
      <div className="card-wrapper" style={{ width: this.props.width }}>
        <a className="course-card" target="_blank" href={ course.url }>
          <div>
            <LazyLoad placeholder={ph}>
              <img src={ course.cover } />
            </LazyLoad>
            <span className="course-type"></span>
          </div>
          <span className="course-name">{ course.title }</span>
        </a>
      </div>
    );
  }
}

CourseCard.propTypes = {
  width: PropTypes.string.isRequired,
  course: PropTypes.object.isRequired,
};

CourseCard.defaultProps = {
  width: '100%',
  course: {}
};

export default CourseCard;

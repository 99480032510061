import { AsyncActionType } from '../../utils';

export const FETCH_TAB_CONTENTS = new AsyncActionType('COLLEGES_TAB', 'FETCH_TAB_CONTENTS');
export const FETCH_COLLEGES = new AsyncActionType('COLLEGES', 'FETCH_COLLEGES');


// FETCH HOT COURSES ACTIONS
export const fetchTabContents = (id, contents) => ({
  type: FETCH_TAB_CONTENTS.BASE,
  payload: { id, contents },
});

export const fetchTabContentsBegin = () => ({
  type: FETCH_TAB_CONTENTS.BEGIN,
});

export const fetchTabContentsSuccess = (payload) => ({
  type: FETCH_TAB_CONTENTS.SUCCESS,
  payload,
});

export const fetchTabContentsFailure = () => ({
  type: FETCH_TAB_CONTENTS.FAILURE,
});

export const fetchTabContentsReset = () => ({
  type: FETCH_TAB_CONTENTS.RESET,
});

// FETCH Colleges ACTIONS
export const fetchColleges = (id) => ({
  type: FETCH_COLLEGES.BASE,
  payload: { id },
});

export const fetchCollegesBegin = () => ({
  type: FETCH_COLLEGES.BEGIN,
});

export const fetchCollegesSuccess = (colleges) => ({
  type: FETCH_COLLEGES.SUCCESS,
  payload: { colleges },
});

export const fetchCollegesFailure = () => ({
  type: FETCH_COLLEGES.FAILURE,
});

export const fetchCollegesReset = () => ({
  type: FETCH_COLLEGES.RESET,
});
